import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'

import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Title from "../Utilities/Title"
const DB = fb.firestore()
const CF = DB.collection('Contact_Form_(Alvvin)');

const Contact = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);

    // contact form

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Message: "",
        Services: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Message, Services } = userData;
        if (Name && Email && Mobile && Message && Services) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.warning('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.warning("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.warning("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Message: Message,
                    Services: Services
                }).then((docRef) => {
                    toast.success('Message Sent Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });

                emailjs.sendForm('service_mzakt66', 'template_ds0kaq7', form.current, 'dmpQBLYvurGfPs337')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Message: "",
                    Services: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.warning("Error adding document")
            }
        }
        else {
            toast.warning('Please fill the data');
        }
    };

    // contact form ends

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className='contact-page'>
            <Header />
            <ToastContainer />

            <section class="contact-page-sec">
                <div class="container">
                    <div class="row">

                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <i class="fas fa-envelope"></i>
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>E-mail</h2>
                                        <span>info@alvvin.com</span>
                                        {/* <span>Sales@alvvin.com</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <i class="fas fa-clock"></i>
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>Call</h2>
                                        <span>+91 124 683 1709</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form action='POST' ref={form} onSubmit={SubmitData}>
                        <h3>Send us a message</h3>
                        <p>Important Notice! We're facing a high volume of query, leading to a potential response to email delays. Our team is working hard to address your inquiries promptly. Your patience is appreciated.</p>
                        <div className="form">
                            <input type="text" placeholder='Full Name'
                                name='Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required
                            />
                            <input type="email" placeholder='Email Address'
                                name='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required
                            />
                            <input type="text" placeholder='Mobile'
                                name='Mobile'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required
                            />
                            <input style={{ display: "none" }}
                                type="text"
                                name="productName"
                                value="Contact Us"
                            />
                            <select id="" className="form-control" name='Services'
                                value={userData.Services}
                                onChange={postUserData} >
                                <option value="retailer/self use" selected hidden>Select Service</option>
                                <option value="Plant & Machinery Maunfacturing">Plant & Machinery Maunfacturing</option>
                                <option value="Oil & Gas Sector">Oil & Gas Sector</option>
                                <option value="Sugar Mills">Sugar Mills</option>
                                <option value="Energy Audits">Energy Audits</option>
                            </select>
                            <textarea id="" placeholder='Message' name='Message'
                                value={userData.Message}
                                onChange={postUserData}></textarea>
                        </div>

                        <button >Send</button>
                    </form>
                    <div className="locations">
                        <Title h3={"Locations"} />

                        <div className="locations-body">
                            <div className="locations-card">
                                <h3>Registered Office</h3>
                                <p>10th Floor, Tower-B,<br/> Unitech Cyber Park, Sector-39,<br/> Gurugram-122001 (Haryana)<br/> INDIA</p>
                                <span>Call: +91 124 683 1709</span><br/>
                                <span>Email: info@alvvin.com</span>
                            </div>

                            <div className="locations-card">
                                <h3>Assam Office</h3>
                                <p>Technology Incubation Centre,<br/> Indian Institute of Technology,<br/> Guwahati-781039, (Assam) <br/>INDIA</p>
                                <span>Call: +91 361 258 3192</span><br/>
                                <span>Email: assam.office@alvvin.com</span>
                            </div>

                            <div className="locations-card">
                                <h3>Saharanpur Office</h3>
                                <p>"Virasat", Gate no. 4,<br/> Paras Puram, Behat Road,<br/> Saharanpur-247001 (Uttar Pradesh) <br/>INDIA </p>
                                <span>Call: +91 931 909 0331</span><br/>
                                <span>Email: sre.office@alvvin.com</span>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Contact