import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import "../Sass/Home.scss"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link as Links, Element } from 'react-scroll';
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
// Images ===============================
import about1 from "../Image/About/about 1.png"
import about2 from "../Image/About/about 2.webp"
import Header from '../Utilities/Header';
import Footer from '../Utilities/Footer';
import slide1 from "../Image/carousel/1.webp"
import slide2 from "../Image/carousel/2.webp"
import slide3 from "../Image/carousel/3.webp"
import Title from '../Utilities/Title';
import service1 from "../Image/Service/service 1.webp"
import service2 from "../Image/Service/service 2.webp"
import service3 from "../Image/Service/service 3.webp"
import service4 from "../Image/Service/service 4.webp"
import n1 from "../Image/Numbers/1.png"
import n2 from "../Image/Numbers/2.png"
import n3 from "../Image/Numbers/3.png"
import project1 from "../Image/Gallery/Erection Work at Panipat Refinary/2.webp"
import project2 from "../Image/Gallery/Panipat Refinary/1.webp"
import project3 from "../Image/Gallery/Assam Site/1.webp"
import client1 from "../Image/Clients/1.webp"
import client2 from "../Image/Clients/2.webp"
import client3 from "../Image/Clients/3.webp"
import client4 from "../Image/Clients/4.webp"
import client5 from "../Image/Clients/5.webp"
import client6 from "../Image/Clients/6.webp"
import client8 from "../Image/Clients/1 - Copy.webp"
import client7 from "../Image/Clients/jakson.webp"
import certificate1 from "../Image/Certificates/1.jpg"
import certificate2 from "../Image/Certificates/2.jpg"
import certificate3 from "../Image/Certificates/3.jpg"
import certificate4 from "../Image/Certificates/4.jpg"
import certificate5 from "../Image/Certificates/5.jpg"
import certificate6 from "../Image/Certificates/6.jpg"
import certificate7 from "../Image/Certificates/7.jpg"
import jakson from "../Image/Gallery/jakson.mp4"
import review from "../Image/review.png"

import a8 from '../Image/Gallery/Panipat Refinary/8.webp'
import a9 from '../Image/Gallery/Panipat Refinary/9.webp'
import a10 from '../Image/Gallery/Panipat Refinary/10.webp'
import a11 from '../Image/Gallery/Panipat Refinary/11.webp'

import i10 from '../Image/Gallery/Assam Site/5.webp'
import i11 from '../Image/Gallery/Assam Site/1.webp'

import s1 from '../Image/Gallery/Erection Work at Panipat Refinary/1.webp'
import s2 from '../Image/Gallery/Erection Work at Panipat Refinary/2.webp'
// =============================================

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Home2 = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    }, []);

    // products

    const responsive = {
        0: { items: 1 },
        // 568: { items: 2 },
        // 1024: { items: 3 },
    };

    const items = [
        <div className="reviews-card">
            <img src={review} alt="" />
            <div className="reviews-card-text">
                <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt fuga suscipit quis magni eos, ducimus quia saepe minus similique mollitia ex dolorum quae necessitatibus repudiandae natus aperiam, ad ipsum culpa? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis eos dolor iure, cumque ducimus maiores animi, aut dolorem quae magni accusantium labore incidunt. Corporis harum ipsum, provident consequatur dolor assumenda? "</p>

                <div className="reviews-des">
                    <h3>Marie Josie</h3>
                    <span>Founder</span>
                </div>

            </div>
        </div >
    ];

    // products ends

    const responsive2 = {
        0: { items: 1 },
        480: { items: 2 },
        768: { items: 3 },
        1024: { items: 4 },
    };

    const items2 = [
        <ul id="Frames">
            <li class="Frame"><img src={certificate1} alt="Certificate 1" /></li>
        </ul>,
        <ul id="Frames">
            <li class="Frame"><img src={certificate2} alt="Certificate 2" /></li>
        </ul>,
        <ul id="Frames">
            <li class="Frame"><img src={certificate3} alt="Certificate 3" /></li>
        </ul>,
        <ul id="Frames">
            <li class="Frame"><img src={certificate4} alt="Certificate 4" /></li>
        </ul>,
        <ul id="Frames" className='card2'>
            <li class="Frame"><img src={certificate5} alt="Certificate 5" /></li>
        </ul>,
        <ul id="Frames" className='card2'>
            <li class="Frame"><img src={certificate6} alt="Certificate 6" /></li>
        </ul>,
        <ul id="Frames" className='card2'>
            <li class="Frame"><img src={certificate7} alt="Certificate 7" /></li>
        </ul>
    ]



    return (

        <>
            <ToastContainer />
            {/* <UpArrow /> */}

            <Header />


            {/* <div className="banner">
                <img src={banner} alt="banner" />

                <div className="banner-text">
                    <h3>The Golden Secret to</h3>
                    <h3 className='h32'>Radiant Health</h3>
                    <a href="#buy">shop now</a>
                </div>
            </div> */}

            <div className="banner">
                <Carousel showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={true} swipeable={false}
                >
                    <div>
                        <img src={slide1} />
                        <div className="banner-text-left">
                            <h3>Engineering the Future, Today</h3>
                            <p>Empowering industries with innovative engineering solutions, cutting-edge technology, and a commitment to excellence. Your vision, our blueprint.</p>
                            <Link to="/contact">Contact Us</Link>
                        </div>
                        <div className="banner-text-right">
                            <h3>Precision. Innovation. Excellence.</h3>
                            <p>We specialize in delivering tailored engineering services that drive efficiency, sustainability, and progress across industries.</p>
                        </div>
                        <div className="banner-img">
                            <img src={slide1} />
                            <img src={slide2} />
                            <img src={slide3} />
                        </div>
                    </div>
                    <div>
                        <img src={slide2} />
                        <div className="banner-text-left">
                            <h3>Building Tomorrow's World Together</h3>
                            <p>From concept to creation, we provide comprehensive engineering solutions that transform ideas into reality, one project at a time.</p>
                            <Link to="/contact">Contact Us</Link>

                        </div>
                        <div className="banner-text-right">
                            <h3>Innovation Beyond Boundaries.</h3>
                            <p>Driven by creativity and expertise, we design solutions that redefine possibilities in engineering and technology.</p>
                        </div>
                        <div className="banner-img">
                            <img src={slide1} />
                            <img src={slide2} />
                            <img src={slide3} />
                        </div>
                    </div>
                    <div>
                        <img src={slide3} />
                        <div className="banner-text-left">
                            <h3>Where Engineering Meets Excellence</h3>
                            <p>Delivering world-class engineering services that combine technical expertise, advanced tools, and a passion for perfection.</p>
                            <Link to="/contact">Contact Us</Link>
                        </div>
                        <div className="banner-text-right">
                            <h3>Design. Develop. Deliver.</h3>
                            <p>We partner with clients to engineer solutions that inspire progress, enhance performance, and create lasting value.</p>
                        </div>
                        <div className="banner-img">
                            <img src={slide1} />
                            <img src={slide2} />
                            <img src={slide3} />
                        </div>
                    </div>
                </Carousel>
            </div>


            <div className="alvvin">
                <Element name="about" className="element">
                    <section className="about" id='about'>

                        <Title h3={"About Our Industry"} />
                        <div className="about-body">
                            <div className="about-img">
                                <img src={about1} alt="" className='about1' />
                                <img src={about2} alt="" />
                            </div>
                            <div className="about-text">
                                <p>At Alvvin Engineering Pvt. Ltd., we are dedicated to
                                    achieving engineering excellence in every aspect of our
                                    work. We strive to push the boundaries of innovation,
                                    employ state-of-the-art technologies, and cultivate a
                                    culture of continuous improvement. </p>
                                <p>Our commitment to engineering excellence is unwavering,
                                    ensuring that each project we undertake reflects the
                                    highest standards of quality, precision, and sustainability. </p>
                                <p>By fostering a team of skilled professionals and embracing
                                    a spirit of creativity, we aim to surpass expectations, solve
                                    complex challenges, and contribute to the advancement
                                    of the engineering industry</p>
                                <Link to="/about">Learn More</Link>
                            </div>
                        </div>
                    </section>
                </Element>
                <section className="products">
                    <Title h3={"Get best industrial services for your business"} />
                    <div className="products-body">


                        {/* <div className="products-card">
                            <div className="img">
                                <img src={service1} alt="service 1" />
                            </div>
                            <div className="product-card-text">
                                <h4>Fabrication</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa quam suscipit laborum esse ut id illum</p>
                                <a href="#">View More</a>
                            </div>
                        </div> */}
                        <div className="products-card">
                            <div className="img">
                                <img src={service2} alt="service 1" />
                            </div>
                            <div className="product-card-text">
                                <h4>Plant And Machinery Manufacturing</h4>
                                <p> Specialized in designing, producing, and maintaining industrial equipment for diverse industries, ensuring high performance, durability, and efficiency.</p>
                                <Link to="/service/plant-and-machinery-manufacturing">View More</Link>

                            </div>
                        </div>
                        <div className="products-card">
                            <div className="img">
                                <img src={service3} alt="service 1" />
                            </div>
                            <div className="product-card-text">
                                <h4>Oil And Gas Sector</h4>
                                <p>Comprehensive services including exploration, extraction, processing, and distribution to meet energy demands, focusing on safety and innovation.</p>
                                <Link to="/service/oil-and-gas-sector">View More</Link>
                            </div>
                        </div>
                        <div className="products-card">
                            <div className="img">
                                <img src={service4} alt="service 1" />
                            </div>
                            <div className="product-card-text">
                                <h4>Sugar Mills</h4>
                                <p> Expertise in manufacturing, installing, and maintaining machinery for efficient sugar production, optimizing operational workflows.</p>
                                <Link to="/service/sugar-mills">View More</Link>

                            </div>
                        </div>
                        <div className="products-card">
                            <div className="img">
                                <img src={service1} alt="service 1" />
                            </div>
                            <div className="product-card-text">
                                <h4>energy audits</h4>
                                <p>Conducting detailed energy assessments to identify savings opportunities, enhance efficiency, and reduce operational costs.</p>
                                <Link to="/service/energy-audits">View More</Link>

                            </div>
                        </div>

                    </div>
                </section>
                <section className="clients">
                    <Title h3={"Those We Serve"} />
                    <div className="clients-body">
                        <img src={client1} alt="" />
                        <img src={client2} alt="" />
                        <img src={client3} alt="" />
                        <img src={client4} alt="" />
                        <img src={client5} alt="" />

                    </div>
                </section>
                <section className="tech">
                    <Title h3={"Our technology backbone"} />
                    <div className="tech-body">
                        <img src={client8} alt="" />
                        <img className='img' src={client6} alt="" />
                    </div>
                </section>
                <section className="tech2">
                    <Title h3={"OEM Reseller’s for Solar Module & Generators"} />
                    <div className="tech-body">
                        <img src={client7} alt="" />
                        <video controls
                            src={jakson}
                        >
                        </video>
                    </div>
                </section>

                <section className="project">
                    <Title h3={"Ongoing projects that will amaze your industry"} />
                    <div className="project-body">
                        <Link to={"/projects"} className="project-card">
                            <div className="img">
                                <img src={project1} alt="project 1" />
                            </div>
                            <div className="project-text">
                                <h3>Erection</h3>
                            </div>
                        </Link>
                        <Link to={"/projects"} className="project-card">
                            <div className="img">
                                <img src={project2} alt="project 1" />
                            </div>
                            <div className="project-text">
                                <h3>Fabrication</h3>
                            </div>
                        </Link>
                        <Link to={"/projects"} className="project-card">
                            <div className="img">
                                <img src={project3} alt="project 1" />
                            </div>
                            <div className="project-text">
                                <h3>Oil and Gas Sector</h3>
                            </div>
                        </Link>
                    </div>
                </section>

                <section className="numbers">
                    <Title h3={" We have multiples years of experience of industries"} />
                    <div className="numbers-body">
                        <div className="numbers-card">
                            <img src={n1} alt="number 1" />
                            <h3><CountUp start={0} end={100} delay={0}>
                                {({ countUpRef, start }) => (
                                    <ReactVisibilitySensor onChange={start} delayedCall={true}>
                                        <span ref={countUpRef} />
                                    </ReactVisibilitySensor>
                                )}
                            </CountUp>+</h3>
                            <p>Project Completed
                                Last 5 Years</p>
                        </div>
                        <div className="numbers-card">
                            <img src={n2} alt="number 1" />

                            <h3><CountUp start={0} end={250} delay={0}>
                                {({ countUpRef, start }) => (
                                    <ReactVisibilitySensor onChange={start} delayedCall={true}>
                                        <span ref={countUpRef} />
                                    </ReactVisibilitySensor>
                                )}
                            </CountUp>+</h3>
                            <p>Experienced team members</p>
                        </div>
                        <div className="numbers-card">
                            <img src={n3} alt="number 1" />

                            <h3><CountUp start={0} end={20} delay={0}>
                                {({ countUpRef, start }) => (
                                    <ReactVisibilitySensor onChange={start} delayedCall={true}>
                                        <span ref={countUpRef} />
                                    </ReactVisibilitySensor>
                                )}
                            </CountUp>+</h3>
                            <p>clients pan India in 10 years </p>
                        </div>
                    </div>
                </section>
                {/* 
                <section className="reviews">
                    <Title h3={"Reviews"} />
                    <div className="reviews-body">
                        <AliceCarousel
                            mouseTracking
                            items={items}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay={true}
                            infinite={true}
                            autoPlayInterval={5500}
                            disableDotsControls={true}
                            renderPrevButton={() => {
                                return <svg className='prev' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                            }}
                            renderNextButton={() => {
                                return <svg className='next' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                            }}
                        />

                    </div>
                </section> */}

                <section className="certificates">
                    <Title h3={"Certificates"} />



                    <div className="certificates-body">

                        {/* <Carousel showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={true}>

                            <div className='certificates-card'>

                                <ul id="Frames">
                                    <li class="Frame"><img src={certificate1} alt="Certificate 1" /></li>
                                </ul>

                                <ul id="Frames">
                                    <li class="Frame"><img src={certificate2} alt="Certificate 1" /></li>
                                </ul>
                                <ul id="Frames">
                                    <li class="Frame"><img src={certificate3} alt="Certificate 1" /></li>
                                </ul>
                                <ul id="Frames">
                                    <li class="Frame"><img src={certificate4} alt="Certificate 1" /></li>
                                </ul>
                            </div>
                            <div className='certificates-card card2'>
                                <ul id="Frames">
                                    <li class="Frame"><img src={certificate5} alt="Certificate 1" /></li>
                                </ul>
                                <ul id="Frames">
                                    <li class="Frame"><img src={certificate6} alt="Certificate 1" /></li>
                                </ul>

                                <ul id="Frames">
                                    <li class="Frame"><img src={certificate7} alt="Certificate 1" /></li>
                                </ul>
                            </div>

                        </Carousel> */}

                        <AliceCarousel
                            mouseTracking
                            items={items2}
                            autoPlay={true}
                            infinite={true}
                            disableDotsControls={true}
                            responsive={responsive2}
                            autoPlayInterval={1000}
                            animationDuration={1000}
                            renderPrevButton={() => {
                                return <span>&#x21e6;</span>
                                // <svg className='prev' id="Layer_1" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 .4c-6.4 0-11.6 5.2-11.6 11.6s5.2 11.6 11.6 11.6 11.6-5.2 11.6-11.6-5.2-11.6-11.6-11.6zm-1.4 15.4-.6-.7 3-3.1-3-3.1.7-.7 3.7 3.7z" /></svg>
                            }}
                            renderNextButton={() => {
                                return <span>&#x21e8;</span>
                                //  <svg className='next' id="Layer_1" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 .4c-6.4 0-11.6 5.2-11.6 11.6s5.2 11.6 11.6 11.6 11.6-5.2 11.6-11.6-5.2-11.6-11.6-11.6zm-1.4 15.4-.6-.7 3-3.1-3-3.1.7-.7 3.7 3.7z" /></svg>
                            }} />

                    </div>
                </section>

                <section className="gallery">
                    <Title h3={"Gallery"} />
                    <div className="gallery-body">
                        <div className="zoom">
                            <Zoom>
                                <img src={a8} alt="" />
                            </Zoom>
                        </div>
                        <div className="zoom">
                            <Zoom>
                                <img src={a9} alt="" />
                            </Zoom>
                        </div>
                        <div className="zoom">
                            <Zoom>
                                <img src={a10} alt="" />
                            </Zoom>
                        </div>
                        <div className="zoom">
                            <Zoom>
                                <img src={a11} alt="" />
                            </Zoom>
                        </div>
                        <div className="zoom">
                            <Zoom>
                                <img src={i10} alt="" />
                            </Zoom>
                        </div>
                        <div className="zoom">
                            <Zoom>
                                <img src={i11} alt="" />
                            </Zoom>
                        </div>
                        <div className="zoom">
                            <Zoom>
                                <img src={s1} alt="" />
                            </Zoom>
                        </div>
                        <div className="zoom">
                            <Zoom>
                                <img src={s2} alt="" />
                            </Zoom>
                        </div>
                    </div>
                </section>

                {/* <section className="contact" id="buy">
                    <Title h3={"Contact Us"} />
                    <div className="contact-body">
                        <img src={buy} alt="buy now" />
                        <form action="POST" ref={form}>
                            <input type="text" placeholder='Full Name'
                                name='Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required
                            />
                            <input type="email" placeholder='Email Address'
                                name='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required
                            />
                            <input type="text" placeholder='Mobile'
                                name='Mobile'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required
                            />
                            <input style={{ display: "none" }}
                                type="text"
                                name="productName"
                                value="Buy Now Form"
                            />
                            <input type="number" placeholder='Quantity' name='Quantity'
                                min={1}
                                value={userData.Quantity}
                                onChange={postUserData}
                                required
                            />
                            <select id="" className="form-control" name='Products'
                                value={userData.Products}
                                onChange={postUserData} >
                                <option value="retailer/self use" selected>Select Products</option>
                                <option value="Kachi Ghani Mustard Oil
">Kachi Ghani Mustard Oil
                                </option>
                                <option value="Refined Soyabean Oil
">Refined Soyabean Oil
                                </option>
                            </select>
                            <button onClick={SubmitData}>Send</button>
                        </form>
                    </div>
                </section> */}
            </div>
            <Footer />

        </>

    )
}

export default Home2