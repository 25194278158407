import React, {useEffect} from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import banner from "../../Image/Service/Plant/banner.webp"
import about from "../../Image/Service/Plant/15.webp"
import Title from "../../Utilities/Title"
import { Link } from 'react-router-dom'

const Plant = () => {
     useEffect(() => {
            // 👇️ scroll to top on page load
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
        }, []);
    return (
        <>
            <Header />


            <div className="about-banner">
                <img src={banner} alt="" />
                <h3>Plant & Machinery Maunfacturing</h3>
            </div>
            <div className='service-page'>

                <section className="service-about">
                    <img src={about} alt="" />
                    <div className="service-about-text">
                        <p>At Alvvin Engineering, we specialize in delivering cutting-edge solutions for plant and machinery manufacturing tailored to your industrial needs. With a focus on innovation, precision, and quality, we design and produce custom-engineered equipment that powers industries across various sectors, including manufacturing, construction, agriculture, and more.</p>

                        <p>With a strong emphasis on innovation and precision, we specialize in creating customized machinery that seamlessly integrates into your workflows. Our end-to-end services cover everything from concept development and engineering design to fabrication, installation, and ongoing maintenance, ensuring you receive a complete solution.</p>

                        <p>We prioritize quality at every step, adhering to stringent standards to produce machinery that is durable, reliable, and energy-efficient. By leveraging the latest technology and sustainable practices, we help businesses reduce costs and minimize environmental impact.</p>
                        <p>Our solutions cater to various industries, including manufacturing, construction, agriculture, and power generation, among others. Whether you need specialized equipment for a complex project or standard machinery for routine operations, we have the expertise to deliver.</p>
                    </div>
                </section>

                <section className="service-expert">
                    <Title h3={"Our Expertise"} />

                    <div className="service-expert-body">
                        <div className="service-expert-card">
                            <h3>Custom Design Solutions</h3>
                            <p>Every piece of machinery is designed to meet your specific requirements, ensuring seamless integration into your operations.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>High-Quality Standards</h3>
                            <p>We adhere to strict quality assurance protocols, ensuring durability, efficiency, and compliance with industry standards.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>End-to-End Services</h3>
                            <p>From initial concept and design to fabrication, installation, and maintenance, we provide comprehensive support throughout the lifecycle of your machinery.</p>
                        </div>
                    </div>
                </section>

                <section className="industries">
                    <Title h3={"Industries we serve"} />

                    <div className="industries-body">

                        <div className="industries-card">
                            <h3>Manufacturing and assembly</h3>
                        </div>

                        <div className="industries-card">
                            <h3>Mining and construction
                            </h3>
                        </div>

                        <div className="industries-card">
                            <h3>Power generation
                            </h3>
                        </div>

                        <div className="industries-card">
                            <h3>Agriculture and food processing
                            </h3>
                        </div>
                    </div>


                </section>

                <section className="service-contact">
                    <div className="service-contact-text">
                        <p>Contact us today to discuss your project and discover how we can bring your vision to life.</p>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default Plant