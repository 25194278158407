import React, {useEffect} from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import banner from "../Image/About/banner.webp"
import one from "../Image/About/1.webp"
import two from "../Image/About/2.webp"
import Title from '../Utilities/Title'

const About = () => {


     useEffect(() => {
            // 👇️ scroll to top on page load
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
        }, []);
         useEffect(() => {
                // 👇️ scroll to top on page load
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        
            }, []);
    return (
        <div className='about-page'>
            <Header />
            <div className="about-banner">
                <img src={banner} alt="" />
                <h3>About Us</h3>
            </div>

            <div className="about-body">
                <img src={one} alt="" />
                <p>Alvvin Engineering Private Limited is at the forefront of the renewable energy revolution, dedicated to delivering
                    comprehensive and sustainable energy solutions. Our mission is to harness the power of nature to meet the diverse
                    energy needs of our clients, promoting environmental stewardship and energy independence. This strategic partnership
                    underscores our commitment to excellence, innovation, and sustainability in the energy sector.</p>

                <p><b>Sponsorship and Support:</b></p>

                <ul>
                    <li><b>OIL INDIA LIMITED:</b> As one of India's premier public sector enterprises, OIL India brings extensive expertise in
                        energy and infrastructure development, ensuring robust support for our projects.</li>
                    <li><b>Indian Institute of Technology, Guwahati (IIT Guwahati):</b> Renowned for its cutting-edge research and
                        technological advancements, IIT Guwahati provides innovative solutions and technical excellence.
                    </li>
                </ul>

                <p><b>Our Expertise:</b> We specialize in a wide range of renewable energy technologies, including:</p>

                <ul>
                    <li><b>Solar Energy:</b> From residential installations to large-scale solar farms, our tailored solutions opmize solar
                        resources to generate clean energy.</li>
                    <li><b>Wind Energy:</b> Our advanced wind turbine technology and wind farm management services harness the power
                        of wind to provide reliable and sustainable energy.</li>
                    <li><b>Hydro Energy:</b> We design and implement efficient hydro-power solutions for both small and large water bodies,
                        ensuring environmentally friendly and effective energy generation.</li>
                    <li><b>Heat Energy:</b> Our geothermal and other heat-based energy solutions provide sustainable heating, cooling, and
                        electrical demands.</li>
                    <li><b>Bio Gas Energy:</b> We develop innovative bio-gas systems that convert organic waste into reliable energy sources,
                        promoting waste management and energy production.</li>
                    <li><b>Other Renewable Solutions: </b>Customized energy solutions based on the unique resources and needs of our
                        clients.</li>
                </ul>
            </div>

            <div className="commitment">
                <div className="img">
                    <img src={two} alt="" />
                </div>
                <p><b>Commitment to Excellence:</b><br /><br /> With the backing of OIL INDIA LIMITED and IIT Guwahati, Alvvin Engineering Pvt. Ltd. is
                    dedicated to advancing the renewable energy landscape through cutting-edge technology and industry best process to
                    deliver efficient, cost-effective, and environmentally conscious energy solutions. Our goal is to create a sustainable
                    future by delivering efficient, cost-effective, and environmentally conscious energy solutions. Our team of skilled
                    professionals works closely with clients to design, implement, and manage renewable energy projects that maximize
                    efficiency and minimize environmental impact. By leveraging our strong partnerships and expertise, Alvvin Engineering
                    Private Limited is poised to make a significant impact in the renewable energy sector, contributing to global sustainability
                    and energy resilience.</p>
            </div>

            <section className="legacy">
                <Title h3={"trusted legacy of engineering excellency"} />
                <p>Alvvin Engineering Private Limited has built a distinguished reputation for engineering excellence and
                    innovation in the renewable energy sector. Our legacy is defined by a commitment to delivering high quality, sustainable energy solutions that meet the evolving needs of our clients and contribute to a
                    greener, more resilient future.</p>
                <div className="legacy-body">

                    <div className="legacy-card">
                        <h3>Pioneering Vision</h3>
                        <p>Founded with a vision to harness the power of nature, Alvvin Engineering is sponsored by OIL INDIA
                            LIMITED and the prestigious Indian Institute of Technology, Guwahati. This strategic partnership
                            underscores our dedication to excellence, innovation, and sustainability.</p>
                    </div>
                    <div className="legacy-card">
                        <h3>Commitment to Quality</h3>
                        <p>Our engineering expertise is reflected in the meticulous design, implementation, and management of
                            renewable energy projects. We adhere to the highest standards of quality and safety, ensuring that every
                            project we undertake meets or exceeds industry standards.</p>
                    </div>
                    <div className="legacy-card">
                        <h3>Innovative Solutions</h3>
                        <p>From solar and wind to hydro and biogas energy, our diverse portfolio of renewable energy solutions
                            demonstrates our ability to adapt and innovate. We leverage cutting-edge technology and industry best
                            process to deliver customized solutions that maximize efficiency and minimize environmental impact.</p>
                    </div>
                </div>
            </section>

            <div className="key">
                <Title h3={"key aspects of our vision and mission"} />
                <div className="key-body">

                    <div className="key-card">
                        <h3>Sustainability</h3>
                        <p>We are dedicated to developing renewable energy projects that reduce carbon
                            footprints, conserve natural resources, and support sustainable development. Our focus on
                            sustainability ensures that our energy solutions are not only effective today but also preserve the
                            environment for future generations.
                        </p>
                    </div>
                    <div className="key-card">
                        <h3>Innovation</h3>
                        <p> Innovation is at the heart of everything we do. By leveraging cutting-edge
                            technology and connuous research, we strive to deliver state-of-the-art energy solutions that
                            push the boundaries of what is possible. Our partnership with leading institutions like OIL INDIA
                            LIMITED and Indian Instute of Technology, Guwahati (IIT Guwahati) fuels our drive for
                            technological excellence and innovation.</p>
                    </div>
                    <div className="key-card">
                        <h3>Excellence</h3>
                        <p>We are commited to maintaining the highest standards of quality and
                            professionalism in all our projects. Our team of skilled engineers and professionals work
                            diligently to ensure that every project meets or exceeds industry standards, delivering
                            exceptional results that our clients can rely on.</p>
                    </div>
                    <div className="key-card">
                        <h3>Client-Centric Approach</h3>
                        <p>Our clients are at the center of our vision. We believe in building
                            strong, lasting relaonships based on trust, transparency, and mutual respect. By understanding
                            our clients' unique needs and challenges, we provide tailored solutions that drive mutual growth
                            and success.</p>
                    </div>
                    <div className="key-card">
                        <h3>Global Impact</h3>
                        <p>Our vision extends beyond borders. We aim to make a significant global impact
                            by contributing to the advancement of renewable energy technologies and solutions. Through
                            strategic partnerships and collaborations, we aspire to drive positive change and energy
                            resilience worldwide.</p>
                    </div>
                    <div className="key-card">
                        <h3>Education and Awareness</h3>
                        <p>We believe in the power of education and awareness to drive
                            change. By eductiang communities, stakeholders, and future generations about the benefits of
                            renewable energy, we foster a culture of sustainability and innovation.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About