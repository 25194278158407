import React, {useEffect} from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import banner from "../../Image/Service/Energy/banner.webp"
import about from "../../Image/Service/Energy/about.webp"
import Title from "../../Utilities/Title"
import { Link } from 'react-router-dom'



const Plant = () => {
     useEffect(() => {
            // 👇️ scroll to top on page load
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
        }, []);
    return (
        <>
            <Header />


            <div className="about-banner">
                <img src={banner} alt="" />
                <h3>Energy Audits</h3>
            </div>
            <div className='service-page energy'>

                <section className="service-about">
                    <img src={about} alt="" />
                    <div className="service-about-text">
                        <p>Reducing energy bills is not just about saving money; it's about creating a sustainable future. Our consultancy specializes in helping businesses and households cut down on their energy consumption, leading to significant cost savings and a smaller carbon footprint. We offer tailored solutions that include energy audits, efficiency upgrades, and renewable energy options. Our team of experts will work closely with you to identify areas of improvement and implement strategies that are both effective and practical. With our guidance, you can achieve a more energy-efficient and environmentally friendly operation and save up to 10-15% in your monthly electrical bills. Let's work together to make your energy bills more manageable and contribute to a greener planet.</p>

                        <p>Through our Energy Audits, we help identify hidden inefficiencies in your current energy systems, uncover opportunities for renewable energy integration, and recommend practical strategies that align with your goals. Whether you are a homeowner looking to reduce monthly utility expenses or a business striving to meet sustainability targets, our comprehensive audits ensure measurable results. Together, we can pave the way for a future that is both economically viable and environmentally responsible.</p>

                        {/*<p>We prioritize quality at every step, adhering to stringent standards to produce machinery that is durable, reliable, and energy-efficient. By leveraging the latest technology and sustainable practices, we help businesses reduce costs and minimize environmental impact.</p>
                        <p>Our solutions cater to various industries, including manufacturing, construction, agriculture, and power generation, among others. Whether you need specialized equipment for a complex project or standard machinery for routine operations, we have the expertise to deliver.</p> */}
                    </div>
                </section>

                <section className="service-expert">
                    <Title h3={"Our Expertise"} />

                    <div className="service-expert-body">
                        <div className="service-expert-card">
                            <h3>Tailored Solutions</h3>
                            <p>Every property is unique. We provide customized energy audit services to meet your specific needs, ensuring maximum efficiency.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>Expert Guidance</h3>
                            <p>Our team of seasoned professionals will work closely with you to identify areas for improvement, offering practical and effective strategies.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>Sustainable Impact</h3>
                            <p>By reducing your energy usage, you not only save money but also contribute to a greener, more sustainable planet.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>Cost Savings</h3>
                            <p>With our recommendations, you can save up to 10-15% on your monthly electrical bills.</p>
                        </div>
                    </div>
                </section>

                <section className="industries">
                    <Title h3={"Benefits of an Energy Audit"} />

                    <div className="industries-body">

                        <div className="industries-card">
                            <h3>Lower Utility Bills</h3>
                        </div>

                        <div className="industries-card">
                            <h3>Environmental Responsibility</h3>
                        </div>

                        <div className="industries-card">
                            <h3>Improved Efficiency</h3>
                        </div>

                        <div className="industries-card">
                            <h3>Increased Profit Value</h3>
                        </div>
                    </div>


                </section>

                <section className="service-contact">
                    <div className="service-contact-text">
                        <p>Contact us today to discuss your energy demands and discover how we can reduce your electrical bills.</p>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default Plant