import React, {useEffect} from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import banner from "../../Image/Service/Sugar/banner.webp"
import about from "../../Image/Service/Sugar/about.webp"
import Title from "../../Utilities/Title"
import { Link } from 'react-router-dom'

const Plant = () => {


     useEffect(() => {
            // 👇️ scroll to top on page load
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
        }, []);

    return (
        <>
            <Header />


            <div className="about-banner">
                <img src={banner} alt="" />
                <h3>Sugar Mills</h3>
            </div>
            <div className='service-page sugar'>

                <section className="service-about">
                    <img src={about} alt="" />
                    <div className="service-about-text">
                        <p>At Alvvin Engineering, we specialize in delivering innovative and reliable engineering solutions for the sugar industry. With years of expertise, we provide end-to-end services, from designing and constructing new sugar mills to upgrading existing facilities. Our mission is to enhance operational efficiency, optimize resource utilization, and maximize productivity for our clients.</p>

                        <p>Our team of skilled engineers and technicians brings deep industry knowledge and cutting-edge technology to every project. Whether it’s turnkey solutions, process optimization, or equipment maintenance, we tailor our services to meet the unique requirements of each client. We prioritize sustainability, helping our clients reduce energy consumption and adopt eco-friendly practices.

                        </p>

                        <p>We proudly serve a diverse range of industries, including cane and beet sugar mills, ethanol production, distilleries, and cogeneration plants. By integrating advanced automation and control systems, we ensure seamless operations and long-term performance.</p>
                        {/* <p>We pride ourselves on being a trusted partner for organizations in the oil and gas sector, driving innovation and efficiency to help them achieve their goals in a rapidly evolving energy landscape.</p> */}
                    </div>
                </section>

                <section className="service-expert">
                    <Title h3={"Our Expertise"} />

                    <div className="service-expert-body">
                        <div className="service-expert-card">
                            <h3>Modernization & Upgrades</h3>
                            <p> Improving efficiency through advanced technologies and retrofitting of existing systems.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>Process Optimization</h3>
                            <p>Implementing solutions to enhance energy savings and maximize sugar recovery.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>Mechanical & Electrical Services</h3>
                            <p>Precision manufacturing, installation, and maintenance of mill components.</p>
                        </div>
                    </div>
                </section>

                {/* <section className="industries">
                    <Title h3={"Industries we serve"} />

                    <div className="industries-body">

                        <div className="industries-card">
                            <h3>Upstream</h3>
                        </div>

                        <div className="industries-card">
                            <h3>Midstream
                            </h3>
                        </div>

                        <div className="industries-card">
                            <h3>Downstream
                            </h3>
                        </div>

                        <div className="industries-card">
                            <h3>Renewable Transition Leaders
                            </h3>
                        </div>
                    </div>


                </section> */}

                <section className="service-contact">
                    <div className="service-contact-text">
                        <p>Contact us today to discuss your project and discover how we can bring your vision to life.</p>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default Plant