import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/Home.scss'
// import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import Home2 from './Components/Pages/Home2';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import Projects from './Components/Pages/Projects';
import Plant from './Components/Pages/Service/Plant';
import Oil from './Components/Pages/Service/oil';
import Sugar from './Components/Pages/Service/Sugar';
import ServiceEnergyAudit from "./Components/Pages/Service/ServiceAudit"
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/service/plant-and-machinery-manufacturing" element={<Plant />} />
          <Route path="/service/oil-and-gas-sector" element={<Oil />} />
          <Route path="/service/sugar-mills" element={<Sugar />} />
          <Route path="/service/energy-audits" element={<ServiceEnergyAudit />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
