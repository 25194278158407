import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../Image/logo/logo.png";
import "../Sass/Home.scss";

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null); // To reference the dropdown container
    const dropdownButtonRef = useRef(null); // To reference the button

    const toggleDropdown = () => {
        setIsDropdownOpen(prev => !prev);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current && !dropdownRef.current.contains(event.target) && 
            dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target)
        ) {
            setIsDropdownOpen(false); // Close the dropdown if clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <header>
                <Link to={"/"} className="logo">
                    <img rel='preload' fetchpriority="high" src={logo} alt="logo" />
                </Link>
                <div className="links">
                    <Link to={"/"}>Home</Link>
                    <Link to={"/about"}>About</Link>
                    <button ref={dropdownButtonRef} onClick={toggleDropdown}>Services</button>
                    {isDropdownOpen && (
                        <ul className='prod' ref={dropdownRef}>
                            <li><Link to={"/service/plant-and-machinery-manufacturing"}>Plant & Machinery Manufacturing</Link></li>
                            <li><Link to={"/service/oil-and-gas-sector"}>Oil & Gas Sector</Link></li>
                            <li><Link to={"/service/sugar-mills"}>Sugar Mills</Link></li>
                            <li><Link to={"/service/energy-audits"}>Energy Audits</Link></li>
                        </ul>
                    )}
                    <Link to={"/projects"}>Projects</Link>
                    <Link to={"/contact"}>Contact Us</Link>
                </div>
            </header>
        </div>
    );
};

export default Header;
