import React from 'react'

const Title = (props) => {

    return (
        <div className='title'>
            {/* <img src={titlebg} alt="title" /> */}
            <h3>{props.h3}</h3>
            <p>{props.p}</p>
        </div>
    )
}

export default Title