import React, {useEffect} from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import banner from "../../Image/Service/Oil/banner.webp"
import about from "../../Image/Service/Oil/about.webp"
import Title from "../../Utilities/Title"
import { Link } from 'react-router-dom'

const Plant = () => {
     useEffect(() => {
            // 👇️ scroll to top on page load
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
        }, []);
    return (
        <>
            <Header />


            <div className="about-banner">
                <img src={banner} alt="" />
                <h3>Oil & Gas Sector</h3>
            </div>
            <div className='service-page oil'>

                <section className="service-about">
                    <img src={about} alt="" />
                    <div className="service-about-text">
                        <p>At Alvvin Engineering, we specialize in delivering innovative and reliable engineering solutions for the oil and gas sector. Our team combines extensive expertise with cutting-edge technology to address the unique challenges of this dynamic industry. From upstream exploration to downstream processing, we provide comprehensive services tailored to meet client needs, ensuring safety, efficiency, and sustainability at every step.</p>

                        <p>Our expertise spans pipeline design and installation, facility engineering, asset integrity management, and process optimization. We are committed to delivering projects on time and within budget while adhering to the highest standards of safety and regulatory compliance. Whether optimizing production systems or managing complex projects, we ensure operational excellence for our clients.</p>

                        <p>We serve a wide array of industries across the oil and gas value chain. This includes upstream exploration and production companies, midstream pipeline operators and processing facilities, and downstream refineries and petrochemical plants. We also partner with oilfield service providers and organizations transitioning to sustainable energy solutions.</p>
                        {/* <p>We pride ourselves on being a trusted partner for organizations in the oil and gas sector, driving innovation and efficiency to help them achieve their goals in a rapidly evolving energy landscape.</p> */}
                    </div>
                </section>

                <section className="service-expert">
                    <Title h3={"Our Expertise"} />

                    <div className="service-expert-body">
                        <div className="service-expert-card">
                            <h3>Pipeline Design and Installation</h3>
                            <p> Ensuring safe, efficient, and reliable transportation of hydrocarbons.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>Facility Engineering</h3>
                            <p>Designing production facilities, refineries, and storage systems with operational efficiency and sustainability in mind.</p>
                        </div>
                        <div className="service-expert-card">
                            <h3>Asset Integrity Management</h3>
                            <p>Providing maintenance, inspection, and risk management to maximize asset lifespan and performance.</p>
                        </div>
                    </div>
                </section>

                {/* <section className="industries">
                    <Title h3={"Industries we serve"} />

                    <div className="industries-body">

                        <div className="industries-card">
                            <h3>Upstream</h3>
                        </div>

                        <div className="industries-card">
                            <h3>Midstream
                            </h3>
                        </div>

                        <div className="industries-card">
                            <h3>Downstream
                            </h3>
                        </div>

                        <div className="industries-card">
                            <h3>Renewable Transition Leaders
                            </h3>
                        </div>
                    </div>


                </section> */}

                <section className="service-contact">
                    <div className="service-contact-text">
                        <p>Contact us today to discuss your project and discover how we can bring your vision to life.</p>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default Plant