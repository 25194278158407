import React, { useState, useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import i1 from '../Image/Gallery/Assam Site/1.webp'
import i5 from '../Image/Gallery/Assam Site/5.webp'
import i6 from '../Image/Gallery/Assam Site/6.webp'
import i8 from '../Image/Gallery/Assam Site/8.webp'
import i9 from '../Image/Gallery/Assam Site/9.webp'
import i11 from '../Image/Gallery/Assam Site/11.webp'

import s1 from '../Image/Gallery/Erection Work at Panipat Refinary/1.webp'
import s2 from '../Image/Gallery/Erection Work at Panipat Refinary/2.webp'
import s3 from '../Image/Gallery/Erection Work at Panipat Refinary/3.webp'
import s4 from '../Image/Gallery/Erection Work at Panipat Refinary/4.webp'

import a1 from '../Image/Gallery/Panipat Refinary/1.webp'
import a2 from '../Image/Gallery/Panipat Refinary/2.webp'
import a3 from '../Image/Gallery/Panipat Refinary/3.webp'
import a4 from '../Image/Gallery/Panipat Refinary/4.webp'
import a5 from '../Image/Gallery/Panipat Refinary/5.webp'
import a6 from '../Image/Gallery/Panipat Refinary/6.webp'
import a7 from '../Image/Gallery/Panipat Refinary/7.webp'
import a8 from '../Image/Gallery/Panipat Refinary/8.webp'
import a9 from '../Image/Gallery/Panipat Refinary/9.webp'
import a10 from '../Image/Gallery/Panipat Refinary/10.webp'
import a11 from '../Image/Gallery/Panipat Refinary/11.webp'
import a12 from '../Image/Gallery/Panipat Refinary/12.webp'
import a14 from '../Image/Gallery/Panipat Refinary/14.webp'
import a16 from '../Image/Gallery/Panipat Refinary/16.webp'
import a20 from '../Image/Gallery/Panipat Refinary/20.webp'
import a25 from '../Image/Gallery/Panipat Refinary/25.webp'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const About = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);

    const images = [{ id: 7, src: a1, category: "fabrication" },
    { id: 8, src: a2, category: "fabrication" },
    { id: 9, src: a3, category: "fabrication" },
    { id: 10, src: a4, category: "fabrication" },
    { id: 11, src: a5, category: "fabrication" },
    { id: 12, src: a6, category: "fabrication" },
    { id: 13, src: a7, category: "fabrication" },
    { id: 1, src: i1, category: "oil and gas sector" },
    { id: 2, src: i11, category: "oil and gas sector" },
    { id: 3, src: i5, category: "oil and gas sector" },
    { id: 4, src: i6, category: "oil and gas sector" },
    { id: 5, src: i8, category: "oil and gas sector" },
    { id: 6, src: i9, category: "oil and gas sector" },

    { id: 14, src: s1, category: "erection" },
    { id: 15, src: s2, category: "erection" },
    { id: 16, src: s3, category: "erection" },
    { id: 17, src: s4, category: "erection" },
    { id: 18, src: a8, category: "erection" },
    { id: 19, src: a9, category: "erection" },
    { id: 20, src: a10, category: "erection" },
    { id: 21, src: a11, category: "erection" },
    { id: 22, src: a12, category: "erection" },
    { id: 23, src: a20, category: "erection" },
    { id: 24, src: a14, category: "erection" },
    { id: 25, src: a16, category: "erection" },
    { id: 26, src: a25, category: "erection" },

    ];

    const categories = ["all", "oil and gas sector", "fabrication", "erection"];

    const [activeCategory, setActiveCategory] = useState("all");

    const handleFilterClick = (category) => {
        if (activeCategory === category) {
            setActiveCategory("all"); // Reset to show all images
        } else {
            setActiveCategory(category);
        }
    };

    const filteredImages =
        activeCategory === "all"
            ? images
            : images.filter((image) => image.category === activeCategory);


    return (
        <div>
            <Header />
            <section className="gallery-page">
                <h3 className="page-title">
                    {/* Welcome to Dwarka School of Music */}
                </h3>
                <div className="gallery-body">
                    <div className="filter-buttons">
                        {categories.map((category) => (
                            <button
                                key={category}
                                className={`filter-button ${activeCategory === category ? "active" : ""
                                    }`}
                                onClick={() => handleFilterClick(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                    <div className="gallery">
                        {filteredImages.map((image) => (
                            <Zoom key={image.id} className="gallery-item">
                                <img key={image.id} src={image.src} alt={image.category} className="gallery-image" />
                            </Zoom>
                        ))}
                    </div>

                </div>
            </section >
            <Footer />

        </div >


    )
}

export default About